import { json } from "@codemirror/lang-json";
import CodeMirror from "@uiw/react-codemirror";
import _ from "lodash";
import { useEffect, useState } from "react";

interface JsonInputProps {
  value: object;
  onChange: (value: object) => void;
}

export const JsonInput = ({ value, onChange }: JsonInputProps) => {
  const [draft, setDraft] = useState(JSON.stringify(value, null, 2));
  const [parsed, setParsed] = useState(_.attempt(() => JSON.parse(draft)));
  const handleChange = (val: string) => {
    setDraft(val);
    setParsed(_.attempt(() => JSON.parse(val)));
  };
  useEffect(() => {
    if (!_.isError(parsed) && !_.isEqual(parsed, value)) {
      onChange(parsed);
    }
  }, [parsed, value, onChange]);
  return (
    <>
      <div className="border border-dark p-3">
        <CodeMirror
          data-axe-exclude
          value={draft}
          extensions={[json()]}
          onChange={handleChange}
          basicSetup={{
            lineNumbers: false,
            foldGutter: false,
            highlightActiveLine: false,
          }}
        />
      </div>
      {_.isError(parsed) && (
        <div className="d-block invalid-feedback">Invalid JSON</div>
      )}
    </>
  );
};
